import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import classnames from "classnames";
import MaskedInput from "next-maskedinput";

import Icon from "../utils/Icon";
import { callNative, fetcher, stringDiff } from "../../utils";

export default function RegisterModal({
  axios,
  show,
  isDarkTheme,
  onHide,
  nameLogin,
  setShowLogin,
  setLoading,
  affiliateCode,
  onError,
  setNameLogin,
  settings,
  showRules,
  loadUser,
  setPage,
  loadSettings
}) {
  if (window && show) {
    const body = window.document.querySelector("body");

    if (!isDarkTheme) {
      body?.classList?.add("light-mode");
      body?.classList?.remove("dark-mode");
    } else {
      body?.classList?.add("dark-mode");
      body?.classList?.remove("light-mode");
    }
  }

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [states, setStates] = useState([]);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [passwordType, setPasswordType] = useState(false);
  const rules = useRef(null);

  useEffect(() => {
    if (!show) {
      return;
    }

    fetcher(axios, "/api/estados").then(({ data }) => {
      if (!data.resposta) {
        alertify.alert(data.mensage, data.descricao);

        return;
      }

      setStates(data.estados);
    });
  }, [show]);

  useEffect(() => {
    if (state) {
      fetcher(axios, "/api/cidades", { estado: state }).then(({ data }) => {
        if (!data.resposta) {
          alertify.alert(data.mensage, data.descricao);

          return;
        }

        setCities(data.cidades);
      });
    }
  }, [state]);

  const handleRegister = () => {
    if (rules.current == null || !rules.current.checked) {
      alertify.alert(
        "Atenção",
        "Você precisa aceitar os termos do regulamento para se cadastrar"
      );

      return;
    }
    setLoading(true);

    const body = {
      name,
      login: username,
      password,
      email,
      telefone: phone.replace('-', '').replace(/\./g, ''),
      cpf: cpf.replace('-', '').replace(/\./g, ''),
      afiliado: affiliateCode || ''
      // 'g-recaptcha-response': captchaToken
    }

    if (state) {
      body.estado = state
    }

    if (city) {
      body.cidade = city
    }

    fetcher(axios, '/api/cadastro/', body)
      .then(({ data }) => {
        setLoading(false);

        if (!data.resposta) {
          alertify.alert(data.mensagem, data.descricao);

          return;
        }

        let message = "Cadastro Realizado.";

        if (settings.confirmar_email) {
          message += ` Um email foi enviado para ${email} com o link de confirmação`;
        }

        alertify.alert("Atenção", message, () => {
          setPassword("");
          setPhone("");
          setEmail("");
          setCpf("");
          setState("");
          setCity("");
          setName("");

          onHide();

          if (settings.login_automatico) {
            autoLogin(body.login, body.password);
          } else {
            setNameLogin(username);
            setShowLogin(true);
            setUsername("");
          }
        });

        if (typeof kwaiq !== 'undefined') {
          kwaiq.track('completeRegistration')
          kwaiq.page('completeRegistration')
        }

        if (typeof ttq !== 'undefined') {
          ttq.track('completeRegistration')
          ttq.page('completeRegistration')
        }

        if (typeof fbq !== 'undefined') {
          fbq('track', 'CompleteRegistration');
        }
      })
      .catch(onError);
  };

  function autoLogin(login, password) {
    const body = { login, senha: password };

    fetcher(axios, "/api/login", body)
      .then(({ data }) => {
        const { token } = data;

        if (localStorage && localStorage.setItem) {
          localStorage.setItem("token", token);
        }

        loadSettings();
        loadUser();

        if (nameLogin != null) {
          setNameLogin(null);
        }

        callNative("onLogin", token);
        setTimeout(() => setPage('deposits'), 1500);
      })
      .catch(onError);
  }

  const handleshowRules = (event) => {
    event.preventDefault();
    showRules();
  };

  const handlePhoneChange = (input) => {
    setTimeout(() => {
      let index = input.value.lastIndexOf(stringDiff(phone, input.value)) + 2;
      const selectedIndex = input.selectionStart;

      if (index > input.value.length) {
        return;
      }

      if (selectedIndex < 5 || selectedIndex === 10) {
        if (selectedIndex === 3) {
          index = 5;
        }

        input.setSelectionRange(index, index);
      }
    }, 50);

    setPhone(input.value);
  };

  return (
    <Modal style={{overflowY: "scroll"}} show={show} onHide={onHide} centered>
      <Modal.Header
          style={{display: "flex", alignItems: "center"}}
          className={classnames("pt-2 pb-2", { "bg-dark": isDarkTheme })}
      >
        <Modal.Title
            style={{display: "flex", gap: "12px", alignItems: "center", paddingLeft: "8px"}}
            className={classnames("display-5", { "text-white": isDarkTheme })}
        >
          <Icon name="user-plus" margin />
          Criar Conta
        </Modal.Title>
        <Button
          variant={isDarkTheme ? "dark" : "light"}
          className={classnames("text-right mt-1", {
            "text-white": isDarkTheme,
          })}
          onClick={onHide}
        >
          <Icon name="times" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-flex h-100 p-3 flex-column align-self-center">
          <div className="pb-3">
            <div className="text-center">
              <div className="display-3 text-999">
                <Icon name="user-circle" />
              </div>
            </div>
            <p className={classnames('text-center', { 'text-light': isDarkTheme })}>
              * Campos Obrigatórios
            </p>
          </div>
          <div className="pb-3">
            <label
              className={classnames({
                "text-light": isDarkTheme,
                "": !isDarkTheme,
              })}
            >
              Nome Completo *
            </label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={classnames("border-0", { "bg-dark": isDarkTheme })}
                >
                  <Icon name="user" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="Digite o seu nome completo"
                spellCheck={false}
                required
                className={classnames("border-0", {
                  "bg-dark text-light": isDarkTheme,
                })}
                value={name}
                onChange={({ target }) => setName(target.value)}
              />
            </InputGroup>
          </div>
          <div className="pb-3">
            <label
              className={classnames({
                "text-light": isDarkTheme,
                "": !isDarkTheme,
              })}
            >
              Login *
            </label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={classnames("border-0", { "bg-dark": isDarkTheme })}
                >
                  <Icon name="user" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="Digite o seu nome de usuário"
                spellCheck={false}
                required
                className={classnames("border-0", {
                  "bg-dark text-light": isDarkTheme,
                })}
                value={username}
                onChange={({ target }) =>
                  setUsername(target.value.replace(/[^a-z]/gi, ""))
                }
              />
            </InputGroup>
          </div>
          <div className="pb-3">
            <label
              className={classnames({
                "text-light": isDarkTheme,
                "": !isDarkTheme,
              })}
            >
              Senha *
            </label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={classnames("border-0", { "bg-dark": isDarkTheme })}
                >
                  <Icon name="key" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type={passwordType ? "text" : "password"}
                autoComplete="off"
                placeholder="Digite a sua senha"
                spellCheck={false}
                required
                className={classnames("border-0", {
                  "bg-dark text-light": isDarkTheme,
                })}
                value={password}
                onChange={({ target }) => setPassword(target.value)}
              />
              <Button
                className={classnames("border-0", {
                  "bg-dark text-light": isDarkTheme,
                  "bg-secondary": !isDarkTheme,
                })}
                variant="outline-secondary"
                onClick={() => setPasswordType((prevState) => !prevState)}
              >
                <Icon name={`eye${passwordType ? "-slash" : ""}`} />
              </Button>
            </InputGroup>
          </div>
          <div className="pb-3">
            <label
              className={classnames({
                "text-light": isDarkTheme,
                "": !isDarkTheme,
              })}
            >
              Email *
            </label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={classnames("border-0", { "bg-dark": isDarkTheme })}
                >
                  <Icon name="at" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                autoComplete="off"
                placeholder="Digite seu email"
                spellCheck={false}
                required={true}
                className={classnames("border-0", {
                  "bg-dark text-light": isDarkTheme,
                })}
                value={email}
                onChange={({ target }) => setEmail(target.value)}
              />
            </InputGroup>
          </div>
          <div className="pb-3">
            <label
              className={classnames({
                "text-light": isDarkTheme,
                "": !isDarkTheme,
              })}
            >
              Telefone *
            </label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={classnames("border-0", { "bg-dark": isDarkTheme })}
                >
                  <Icon name="phone" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as={MaskedInput}
                autoComplete="off"
                placeholder="Digite seu telefone"
                spellCheck={false}
                required
                mask="(11) 11111-1111"
                className={classnames("border-0", {
                  "bg-dark text-light": isDarkTheme,
                })}
                value={phone}
                onChange={({ target }) => handlePhoneChange(target)}
              />
            </InputGroup>
          </div>
          <div className="pb-3">
            <label
              className={classnames({
                "text-light": isDarkTheme,
                "": !isDarkTheme,
              })}
            >
              CPF *
            </label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={classnames("border-0", { "bg-dark": isDarkTheme })}
                >
                  <Icon name="user" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                autoComplete="off"
                as={MaskedInput}
                className={classnames("border-0", {
                  "bg-dark text-light": isDarkTheme,
                })}
                placeholder=" Digite seu CPF"
                spellCheck={false}
                required
                mask="111.111.111-11"
                value={cpf}
                onChange={({ target }) => setCpf(target.value)}
              />
            </InputGroup>
          </div>
          {settings.mostrar_campos_endereco && <>
            <div className="pb-3">
              <label
                className={classnames({
                  "text-light": isDarkTheme,
                  "": !isDarkTheme,
                })}
              >
                Estado
              </label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    className={classnames("border-0", { "bg-dark": isDarkTheme })}
                  >
                    <Icon name="pennant" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  autoComplete="off"
                  spellCheck={false}
                  required
                  className={classnames("border-0", {
                    "bg-dark text-light": isDarkTheme,
                  })}
                  onChange={({ target }) => setState(target.value)}
                >
                  <option value="">Selecione seu estado</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.nome}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
            </div>
            <div className="pb-3">
              <label
                className={classnames({
                  "text-light": isDarkTheme,
                  "": !isDarkTheme,
                })}
              >
                Cidade
              </label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    className={classnames("border-0", { "bg-dark": isDarkTheme })}
                  >
                    <Icon name="city" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  autoComplete="off"
                  spellCheck={false}
                  required
                  className={classnames("border-0", {
                    "bg-dark text-light": isDarkTheme,
                  })}
                  disabled={!state}
                  onChange={({ target }) => setCity(target.value)}
                >
                  <option disabled={!!state}>Escolha a cidade</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.nome}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
            </div>
          </>}
          <div>
            <InputGroup>
              <Form.Check type="checkbox" id="rules-agreement">
                <Form.Check.Input ref={rules} type="checkbox" />
                <Form.Check.Label className={isDarkTheme && "text-white"}>
                  Aceito os termos do{" "}
                  <a style={{ cursor: "pointer" }} onClick={handleshowRules}>
                    <b>regulamento</b>
                  </a> {" *"}
                </Form.Check.Label>
              </Form.Check>
            </InputGroup>
          </div>
          <Button
            className="my-3"
            type="button"
            variant="primary"
            block
            onClick={() => handleRegister()}
          >
            <Icon name="sign-in-alt" margin />
            Registrar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
