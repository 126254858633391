import Link from "next/link";
import classnames from "classnames";
import { useState } from "react";
import {
  Row,
  Col,
  Badge,
  Button,
  Dropdown,
  Nav,
  Navbar,
} from "react-bootstrap";
import SkeletonLoader from "../layout/SkeletonLoader";
import styles from "./index.module.css";
import Sidebar from "../layout/Sidebar";
import Icon from "../utils/Icon";
import { callNative, formatNumber, showAttendance, fetcher } from "../../utils";

export default function AppNavbar({
  apiUrl,
  gamesLength,
  affiliateCode,
  axios,
  user,
  page,
  setPage,
  championships,
  sport,
  setSport,
  initialPage,
  selectedChampionship,
  onItemClick,
  isMobile,
  isDarkTheme,
  settings,
  setLoading,
  showRules,
  showBetRules,
  loadUser,
  loadSettings,
  userLoading,
  setUserLoading,
  onError,
  refreshBalance,
}) {
  const [showSidebar, setShowSidebar] = useState(false);

  const openSidebar = () => {
    setShowSidebar(true);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  const handleSidebarItemClick = (item, data) => {
    onItemClick(item, data);
    closeSidebar();
  };

  const logout = () => {
    setLoading(true);

    fetcher(axios, "/api/logout", {})
      .then(({ data }) => {
        if (!data?.resposta) {
          setLoading(false);
          alertify.alert(data?.mensagem, data?.descricao);
          return;
        }

        localStorage.removeItem("token");
        callNative("onLogout");
        window.location.reload();
      })
      .catch(onError);
  };

  const renderLogged = () => {
    if (user) {
      return (
        <>
          {user?.nivel === 3 && (
            <Nav.Item className="mr-2">
              <Button variant="primary" onClick={() => onItemClick("deposits")}>
                <Icon name="dollar-sign" margin />
                Depositar
              </Button>
            </Nav.Item>
          )}
          <Nav.Item>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary">
                <Icon
                  name={user?.nivel === 2 ? "coins" : "user-circle"}
                  margin
                />
                {user?.nivel === 2 ? "Caixa" : "Conta"}
              </Dropdown.Toggle>
              <Dropdown.Menu align="right">
                <Dropdown.ItemText>Olá, {user?.nome}</Dropdown.ItemText>
                <Dropdown.Divider />
                {user?.nivel === 2 && (
                  <>
                    <Dropdown.Header>Crédito</Dropdown.Header>
                    <Dropdown.ItemText>
                      R$ {formatNumber(user?.credito)}
                    </Dropdown.ItemText>
                  </>
                )}
                <Dropdown.Header>Saldo</Dropdown.Header>
                <Dropdown.ItemText
                  className={
                    !userLoading &&
                    "d-flex align-items-center justify-content-between"
                  }
                >
                  {userLoading ? (
                    <SkeletonLoader color="#ddd" />
                  ) : (
                    <>
                      R$ {formatNumber(user?.saldo)}
                      <Icon name="sync-alt" onClick={() => refreshBalance()} />
                    </>
                  )}
                </Dropdown.ItemText>
                {user?.nivel === 3 && user?.saldo_bonus > 0 && (
                  <>
                    <Dropdown.Header>Bônus</Dropdown.Header>
                    <Dropdown.ItemText
                      className={
                        !userLoading &&
                        "d-flex align-items-center justify-content-between"
                      }
                    >
                      {userLoading ? (
                        <SkeletonLoader color="#ddd" />
                      ) : (
                        <>
                          R$ {formatNumber(user?.saldo_bonus)}
                          <Icon
                            name="sync-alt"
                            onClick={() => refreshBalance()}
                          />
                        </>
                      )}
                    </Dropdown.ItemText>
                  </>
                )}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => onItemClick("account")}>
                  <Icon name="user" margin />
                  {user?.nivel === 2 ? "Caixa" : "Painel"}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => logout()}>
                  <Icon name="sign-out-alt" margin />
                  Sair
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        </>
      );
    }

    return (
      <>
        {settings?.modulo_apostador && settings?.cadastrar_apostador && (
          <Nav.Item className="my-2 my-sm-0 mr-2">
            <Button
              variant="secondary"
              block
              onClick={() => onItemClick("register")}
            >
              <Icon className="mr-2" name="user-plus" />
              Criar Conta
            </Button>
          </Nav.Item>
        )}
        <Nav.Item>
          <Button variant="primary" block onClick={() => onItemClick("login")}>
            <Icon className="mr-2" name="sign-in-alt" /> Entrar
          </Button>
        </Nav.Item>
      </>
    );
  };

  const renderBtnNavbar = () => {
    if (isMobile) {
      return (
        <Row>
          {settings?.habilitar_atendimento && !user ? (
            <Col className=" pl-2 pr-0">
              <Button
                className={classnames([
                  "button-navbar-mobile border-0",
                  { "": isDarkTheme },
                ])}
                variant="outline-secondary"
                onClick={() => showAttendance(settings)}
              >
                <Icon name="headset" />
              </Button>
            </Col>
          ) : user && user?.nivel ? (
            <Col className="pl-2 pr-0">
              <Button
                className="button-navbar-mobile border-0"
                variant="outline-secondary"
                onClick={() => onItemClick("account")}
              >
                <Icon name={user.nivel === 2 ? "coins" : "user-circle"} />
              </Button>
            </Col>
          ) : null}
        </Row>
      );
    }
    return null;
  };

  const renderNavbarContent = () => {
    if (isMobile) {
      return (
        <Row className={styles.MobileWrapper}>
          {settings?.modulo_apostador && settings?.cadastrar_apostador && (
            <Col className="pl-2 pr-0">
              <Button
                className={styles.DepositButton}
                onClick={() =>
                  !user ? onItemClick("login") : onItemClick("deposits")
                }
              >
                <Icon name="sack-dollar" />
                <span>Depositar</span>
              </Button>
            </Col>
          )}
          <Col className="pl-0 pr-2">
            <Button
              className="button-navbar-mobile border-0"
              variant="outline-secondary"
              onClick={openSidebar}
            >
              <Icon name="bars" />
            </Button>
          </Col>
        </Row>
      );
    }

    return (
      <>
        <Nav className="mr-auto">
          {page !== "index" && (
            <Nav.Link
              active={page === "sports"}
              onClick={() => onItemClick("sports")}
            >
              <Icon name="fire" margin />
              {sport === "casino" ? "Jogos" : "Eventos"}
              <Badge variant="primary" className="ml-2">
                {sport === "casino"
                  ? gamesLength
                  : championships?.reduce((c, x) => c + x?.matches.length, 0)}
              </Badge>
            </Nav.Link>
          )}
          {user && championships?.length > 0 && sport !== "casino" && (
            <Nav.Link
              active={page === "table"}
              onClick={() => onItemClick("table")}
            >
              <Icon name="print" margin />
              Tabela
            </Nav.Link>
          )}
          {user && ((Object.keys(settings?.esportes) || []).length > 0 || !!settings?.habilitar_ao_vivo) && (
            <Nav.Link
              active={page === "bets"}
              onClick={() => onItemClick("bets")}
            >
              <Icon name="receipt" margin />
              Apostas
            </Nav.Link>
          )}
          {settings.habilitar_busca_bilhete && <Nav.Link onClick={() => onItemClick("ticket")}>
            <Icon name="search" margin /> Ver Bilhete
          </Nav.Link>}
          {user && user?.nivel !== 3 && settings?.habilitar_pre_bilhete && (
            <Nav.Link onClick={() => onItemClick("validate")}>
              <Icon name="check" margin /> Validar Bilhete
            </Nav.Link>
          )}
          <Nav.Link onClick={() => showRules()}>
            <Icon name="book" margin />
            {settings?.titulo_regras || "Regras"}
          </Nav.Link>
          <Nav.Link onClick={() => showBetRules()}>
            <Icon name="shield-alt" margin />
            {settings?.titulo_ajuda || "Ajuda"}
          </Nav.Link>
          {settings?.habilitar_atendimento && (
            <Nav.Link onClick={() => showAttendance(settings)}>
              <Icon name="headset" margin /> Atendimento
            </Nav.Link>
          )}
        </Nav>
        <Nav>{renderLogged()}</Nav>
      </>
    );
  };

  return (
    <>
      <Navbar expand="lg" variant={isDarkTheme ? "dark" : "light"}>
        {renderBtnNavbar()}
        <Link href="/">
          <Navbar.Brand
            className={styles.Logo}
            onClick={() => {
              if (initialPage === "index") {
                setPage("index");
              } else {
                initialPage === "casino"
                  ? setSport("casino")
                  : setSport(settings?.esportes[0]?.key);
                setPage("sports");
              }
            }}
          >
            <img
              src={
                settings?.logo ||
                `/images/logo${!isDarkTheme ? "-dark" : ""}.png`
              }
              alt="InoBets"
              height="30"
              style={{ cursor: "pointer" }}
            />
          </Navbar.Brand>
        </Link>
        {renderNavbarContent()}
      </Navbar>
      <Sidebar
        apiUrl={apiUrl}
        affiliateCode={affiliateCode}
        user={user}
        gamesLength={gamesLength}
        sport={sport}
        loadUser={loadUser}
        page={page}
        championships={championships}
        selectedChampionship={selectedChampionship}
        show={showSidebar}
        onHide={closeSidebar}
        onItemClick={handleSidebarItemClick}
        logout={logout}
        isDarkTheme={isDarkTheme}
        settings={settings}
        loadSettings={loadSettings}
        showRules={showRules}
        showBetRules={showBetRules}
        setLoading={setLoading}
      />
    </>
  );
}
