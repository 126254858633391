import classnames from 'classnames';

import styles from "./index.module.css";

const CategoriesCards = ({ setPage, setSport, isDarkTheme, sports, hasCasino, hasLive }) => {
  const handleClick = (sport: string) => {
    setPage("sports");
    setSport(sport);
  };

  return (
    <nav className={styles.Wrapper}>
      <button
        type="button"
        className={classnames(styles.Card, { "bg-dark text-light": isDarkTheme, "bg-white": !isDarkTheme })}
        onClick={() => handleClick(sports[0]?.key || "soccer")}
      >
        <i className="fas fa-running"></i>
        Esportes
      </button>
      {hasLive ? (
        <button
          type="button"
          className={classnames(styles.Card, { "bg-dark text-light": isDarkTheme, "bg-white": !isDarkTheme })}
          onClick={() => handleClick("live")}
        >
          <i className="fas fa-futbol"></i>
          Esportes Ao Vivo
        </button>
      ) : null}
      {hasCasino ? (
        <>
          <button
            type="button"
            className={classnames(styles.Card, { "bg-dark text-light": isDarkTheme, "bg-white": !isDarkTheme })}
            onClick={() => handleClick("casino")}
          >
            <i className="fas fa-dice"></i>
            Cassino
          </button>
          <button
            type="button"
            className={classnames(styles.Card, { "bg-dark text-light": isDarkTheme, "bg-white": !isDarkTheme })}
            onClick={() => handleClick("casino-live")}
          >
            <i className="fas fa-money-bill-alt"></i>
            Cassino Ao Vivo
          </button>
        </>
      ) : null}
    </nav>
  );
};

export default CategoriesCards;
