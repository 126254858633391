import styles from "./index.module.css";

const CardBanners = ({ setPage, setSport, isDarkTheme, hasCasino, hasLive }) => {
  const handleClick = () => {
    setPage("sports");
    setSport("live");
  };

  const handleCasinoClick = () => {
    setPage("sports");
    setSport("casino");
  };

  const imageTheme = isDarkTheme ? "" : "-light";

  return (
    <section className={styles.Wrapper}>
      {hasLive ?
        <div className={styles.CardWrapper} onClick={() => handleClick()}>
          <picture>
            <source
              srcSet={`./images/landingPage/bannerLive${imageTheme}.png`}
              media="(min-width: 600px)"
            />
            <img src={`./images/landingPage/bannerLiveMobile${imageTheme}.png`} />
          </picture>
          <div className={styles.InfoWrapper}>
            <h3>Apostas ao vivo</h3>
            <p>
              Mais de 90.000+ jogos ao vivo por mês e milhares de opções para
              apostas!
            </p>
            <button
              className={`${styles.Button} btn-primary`}
              type="button"
              onClick={() => handleClick()}
            >
              Apostar
            </button>
          </div>
        </div>
      : null}
      <div className={styles.CardWrapper} onClick={() => setPage("sports")}>
        <picture>
          <source
            srcSet={`./images/landingPage/bannerSports${imageTheme}.png`}
            media="(min-width: 600px)"
          />
          <img
            src={`./images/landingPage/bannerSportsMobile${imageTheme}.png`}
          />
        </picture>
        <div className={`${styles.InfoWrapper} ${styles.AlignRight}`}>
          <button
            className={`${styles.Button} btn-primary`}
            type="button"
            onClick={() => setPage("sports")}
          >
            Apostar
          </button>
          <p>Aposte nos principais eventos da semana!</p>
          <h3>Apostas Esportivas</h3>
        </div>
      </div>
      {hasCasino ?
        <div className={styles.CardWrapper} onClick={() => handleCasinoClick()}>
          <picture>
            <source
              srcSet={`./images/landingPage/bannerCasino${imageTheme}.png`}
              media="(min-width: 600px)"
            />
            <img
              src={`./images/landingPage/bannerCasinoMobile${imageTheme}.png`}
            />
          </picture>
          <div className={styles.InfoWrapper}>
            <h3>Cassino</h3>
            <p>Os melhores provedores de cassino do mundo.</p>
            <button
              className={`${styles.Button} btn-primary`}
              type="button"
              onClick={() => handleCasinoClick()}
            >
              Jogar
            </button>
          </div>
        </div>
      : null}
    </section>
  );
};

export default CardBanners;
