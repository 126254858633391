import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, ListGroup, Modal, Nav, Row, Tab } from 'react-bootstrap'
import classnames from 'classnames'

import Icon from '../utils/Icon'
import { copyText, fetcher } from '../../utils'

const GOOGLE_QR_URL = 'https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=[data]&chld=L|1';

export default function BankDepositModal({ show, deposit, setDeposit, axios, settings, isDarkTheme, onUpdateDepositStatus, onHide, onError, loadUser, loadDeposits, isAutomaticDeposit, setLoading }) {
  if (window && show) {
    const body = window.document.querySelector("body");

    if (!isDarkTheme) {
      body?.classList?.add("light-mode");
      body?.classList?.remove("dark-mode");
    } else {
      body?.classList?.add("dark-mode");
      body?.classList?.remove("light-mode");
    }
  }

  const [file, setFile] = useState(undefined)
  const [fileName, setFileName] = useState('Comprovante de Depósito');
  const [pix, setPix] = useState(null)
  const needPix = settings.habilitar_pix && settings.conta_banco

  useEffect(() => {
    if (show && isAutomaticDeposit) {
      const interval = setInterval(() => {
        const body = {
          deposito: deposit.id,
        }

        fetcher(axios, '/api/verificarPix', body)
          .then(({ data }) => {
            if (data.resposta) {
              if (data.pago) {
                clearInterval(interval)
                onHide()
                loadUser()
                loadDeposits()

                if (typeof kwaiq !== 'undefined') {
                  kwaiq.track('purchase')
                  kwaiq.page('purchase')
                }

                if (typeof ttq !== 'undefined') {
                  ttq.track('purchase')
                  ttq.page('purchase')
                }

                if (typeof fbq !== 'undefined') {
                  fbq('track', 'Purchase');
                }
              }
            } else {
              alertify.alert(data.mensagem, data.descricao)
            }
          }).catch(onError)
      }, 10000)

      return () => clearInterval(interval)
    }
  }, [show, deposit, onHide, onError, loadUser])

  const loadDeposit = () => {
    if (show && isAutomaticDeposit && deposit.status != 'Finalizado' && !deposit.pix) {
      setLoading(true)
      fetcher(axios, '/api/deposito?deposito=' + deposit.id)
      .then(({ data }) => {
        if (data.resposta)
          setDeposit(data.deposito)
        else {
          alertify.alert(data.mensagem, data.descricao)
          onHide()
        }

        setLoading(false)
      })
    }
  }

  const submitReceipt = () => {
    if (!file)
      return

    const data = new FormData()
    data.append('id', deposit.id)
    data.append('file', file)

    if (settings.habilitar_pix) {
      if (settings.conta_banco) {
        if (pix === null) {
          alertify.alert("Atenção!", "Informe se o pagamento foi realizado via Pix")
          return
        }

        data.append('pix', +pix)
      } else {
        data.append('pix', 1)
      }
    }

    fetcher(axios, '/api/comprovante', data,  {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then(({data}) => {
        if (data.resposta) {
          onUpdateDepositStatus(deposit.id, 2)
          setFile(undefined)
          setFileName('Comprovante de Depósito')
          onHide()
        } else {
          alertify.alert(data.mensagem, data.descricao)
        }
      }).catch(onError)
  }

  const handleProof = (files) => {
    if (files[0]) {
      setFile(files[0])
      setFileName(files[0].name || 'Comprovante de Depósito')
    } else {
      setFile(undefined)
      setFileName('Comprovante de Depósito')
    }
  }

  const isPixEnabled = () => {
    return settings.habilitar_pix && settings.pix_config && settings.pix
  }

  const renderListItem = ({text, value}, key) => {
    return (
      <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} key={key}>
        <span className="font-weight-bold">{text}</span>
        <span className="float-right">{value}</span>
      </ListGroup.Item>
    )
  }

  const renderInput = () => {
    if (isAutomaticDeposit)
      return (
        <InputGroup className="mb-3">
          <Form.Control defaultValue={deposit.pix} readOnly />
          <InputGroup.Append style={{ cursor: 'pointer' }}>
            <InputGroup.Text onClick={() => copyText(deposit.pix)}>
              <Icon name="copy" />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      )

    return (
      <Form>
        <Form.File
          label={fileName || "Comprovante de Depósito"}
          onChange={({target}) => handleProof(target.files)}
          custom
          accept=".png,.jpg"
        />
        {needPix && file && <Row className={classnames(['my-2', { 'text-light': isDarkTheme }])}>
          <Col>
            <ListGroup>
              {[
                {text: 'Você depositou através do Pix?', value: (<>
                  <Form.Check
                    type="radio"
                    id="radio-pix-y"
                    label="Sim"
                    name="pix"
                    custom
                    className="custom-radio"
                    inline
                    onChange={({target}) => target.checked && setPix(1)}
                  />
                  <Form.Check
                    value=""
                    type="radio"
                    id="radio-pix-n"
                    label="Não"
                    name="pix"
                    custom
                    className="custom-radio"
                    inline
                    onChange={({target}) => target.checked && setPix(0)}
                  />
                </>)},
              ].map(renderListItem)}
            </ListGroup>
          </Col>
        </Row>}
      </Form>
    )
  }

  return (
    <Modal show={show} onHide={() => {}} onEntering={() => loadDeposit()} centered>
        <Modal.Header className={classnames(['pt-2 pb-2', { 'bg-dark': isDarkTheme }])}>
          <Modal.Title className={classnames(['display-5', { 'text-white': isDarkTheme }])}>
            {isAutomaticDeposit ? 'Pagar' : 'Enviar Comprovante'}
          </Modal.Title>
          <Button variant={isDarkTheme ? 'dark' : 'light'} className={classnames(['text-right mt-1', { 'text-white': isDarkTheme }])} onClick={onHide}>
            <Icon name="times" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container defaultActiveKey={isPixEnabled() || isAutomaticDeposit ? 'pix' : 'wire'}>
            {!isAutomaticDeposit && isPixEnabled() && settings.conta_banco && <Row>
              <Col>
                <Nav justify variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="pix">Pix</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="wire">Dados Bancários</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>}
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="pix">
                    <Container>
                      <Row className="my-2">
                        <Col xs={{span: 6, offset: 3}}>
                          <img src={isAutomaticDeposit ? deposit.qr_code_url : (settings.pix ? GOOGLE_QR_URL.replace('[data]', encodeURIComponent(settings.pix_config)) : '')} className="img-responsive" alt="Chave Pix" />
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col>
                          <ListGroup>
                            <ListGroup.Item className="text-center" variant={isDarkTheme ? 'dark' : ''}>
                              Leia o QR Code com o aplicativo do seu banco ou {isAutomaticDeposit ? 'clique no ícone abaixo para copiar o Pix Copia e Cola' : 'digite a Chave Pix abaixo'}
                            </ListGroup.Item>
                            {!isAutomaticDeposit && settings.pix && [
                              {text: 'Beneficiário', value: settings.pix.nome},
                              {text: 'Chave Pix', value: settings.pix.chave},
                            ].map(renderListItem)}
                          </ListGroup>
                        </Col>
                      </Row>
                    </Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="wire">
                    {!isAutomaticDeposit && settings.conta_banco &&
                      <ListGroup>
                        {[
                          {text: 'Banco',value: settings.conta_banco.banco},
                          {text: 'Agência', value: settings.conta_banco.agencia},
                          {text: settings.conta_banco.tipo_conta, value: settings.conta_banco.conta},
                          {text: 'Titular',value: settings.conta_banco.titular},
                          {text: 'CPF/CNPJ', value: settings.conta_banco.titular_cpf},
                          {text: 'Valor para Depósito', value: deposit && deposit.valor},
                          {text: 'Telefone',value: settings.telefone},
                        ].map(renderListItem)}
                      </ListGroup>
                    }
                  </Tab.Pane>
              </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          {renderInput()}
        </Modal.Body>
        <Modal.Footer>
          {isAutomaticDeposit ? <Button variant="success" onClick={onHide}>Fechar</Button>
           :
           <>
            <Button variant="danger" onClick={onHide}>Cancelar</Button>
            <Button variant="success" onClick={() => submitReceipt()}>Enviar</Button>
          </>}
        </Modal.Footer>
      </Modal>
  )
}
