import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import classnames from "classnames";

import Icon from "../utils/Icon";
import { callNative, fetcher } from "../../utils";

export default function LoginModal({
  axios,
  show,
  isDarkTheme,
  onHide,
  setLoading,
  loadUser,
  loadSettings,
  nameLogin,
  setNameLogin,
  onError,
  settings,
  setNeedRecoverPassword,
  onItemClick,
  setShowLogin,
}) {
  if (window && show) {
    const body = window.document.querySelector("body");

    if (!isDarkTheme) {
      body?.classList?.add("light-mode");
      body?.classList?.remove("dark-mode");
    } else {
      body?.classList?.add("dark-mode");
      body?.classList?.remove("light-mode");
    }
  }

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const inputPswd = useRef(null);

  useEffect(() => {
    if (!show) {
      return;
    }

    if (nameLogin != null && inputPswd.current != null) {
      setLogin(nameLogin);

      setTimeout(() => {
        inputPswd.current.focus();
      }, 1000);
    }
  }, [nameLogin, inputPswd.current, show]);

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);

    setLoading(true);

    const body = { login, senha: password };

    fetcher(axios, "/api/login", body)
      .then(({ data }) => {
        setLoading(false);

        if (!data.resposta) {
          if (data.confirmar) {
            alertify
              .confirm(
                data.mensagem,
                data.descricao,
                () => {
                  handleHide();
                  setLogin("");
                  setPassword("");

                  alertify
                    .prompt(
                      "Reenviar E-mail de Confirmação",
                      "Confirme seu endereço de e-mail",
                      data.email,
                      (evt, value) => {
                        if (!value) {
                          evt.cancel = true;
                          return;
                        }

                        body.email = value;

                        setLoading(true);
                        fetcher(axios, "/api/reconfirmar-email", body)
                          .then(({ data }) => {
                            setLoading(false);

                            if (!data.resposta) {
                              alertify.alert(data.mensagem, data.descricao);

                              return;
                            }

                            alertify.alert(
                              "Atenção",
                              "Um novo e-mail foi enviado para confirmar o seu endereço de e-mail"
                            );
                          })
                          .catch(onError);
                      },
                      () => {}
                    )
                    .set({
                      type: "email",
                      padding: true,
                      labels: { ok: "Enviar", cancel: "Cancelar" },
                    });
                },
                () => {}
              )
              .set({ padding: true, labels: { ok: "Sim", cancel: "Não" } });
          } else {
            alertify.alert(data.mensagem, data.descricao);
          }

          return;
        }

        const { token } = data;

        if (localStorage && localStorage.setItem) {
          localStorage.setItem("token", token);
        }

        loadSettings();
        loadUser();

        if (nameLogin != null) {
          setNameLogin(null);
        }

        onHide();
        callNative("onLogin", token);
      })
      .catch(onError);
  };

  const handleHide = () => {
    if (nameLogin != null) {
      setNameLogin(null);
    }

    onHide();
  };

  const handleRecover = () => {
    setNeedRecoverPassword(true);
    handleHide();
  };

  return (
    <Modal show={show} onHide={() => handleHide()} centered>
      <Modal.Header
        className={classnames(["pt-2 pb-2", { "bg-dark": isDarkTheme }])}
      >
        <Modal.Title
          className={classnames("display-5", { "text-white": isDarkTheme })}
        >
          <Icon name="globe" margin />
          Login
        </Modal.Title>
        <Button
          variant={isDarkTheme ? "dark" : "light"}
          className={classnames([
            "text-right mt-1",
            { "text-white": isDarkTheme },
          ])}
          onClick={onHide}
        >
          <Icon name="times" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="d-flex h-100 p-3 flex-column align-self-center"
          onSubmit={handleLogin}
        >
          <div className="pb-3">
            <div className="text-center">
              <div className="display-3 text-999">
                <Icon name="user-circle" />
              </div>
            </div>
          </div>
          <div className="pb-3">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={classnames([
                    "border-0",
                    { "bg-dark": isDarkTheme },
                  ])}
                >
                  <Icon name="user" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                autoComplete="off"
                placeholder="Usuário"
                spellCheck={false}
                required={true}
                className={classnames([
                  "border-0",
                  {
                    "bg-dark text-light": isDarkTheme,
                  },
                ])}
                value={login}
                onChange={({ target }) => setLogin(target.value)}
              />
            </InputGroup>
          </div>
          <div className="pb-3">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={classnames([
                    "border-0",
                    { "bg-dark": isDarkTheme },
                  ])}
                >
                  <Icon name="key" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="password"
                ref={inputPswd}
                autoComplete="off"
                placeholder="Senha"
                spellCheck={false}
                required={true}
                className={classnames([
                  "border-0",
                  {
                    "bg-dark text-light": isDarkTheme,
                  },
                ])}
                value={password}
                onChange={({ target }) => setPassword(target.value)}
              />
            </InputGroup>
          </div>
          <div
            className={classnames("pb-3 text-right", {
              "text-white": isDarkTheme,
            })}
          >
            <a style={{ cursor: "pointer" }} onClick={() => handleRecover()}>
              Esqueceu a senha?
            </a>
          </div>
          <Button type="submit" variant="primary" block onClick={handleLogin}>
            <Icon name="sign-in-alt" margin />
            Entrar
          </Button>
          <Button
            type="button"
            variant="secondary"
            block
            onClick={() => {
              onItemClick('register');
              setShowLogin(false);
            }}
          >
            <Icon name="user-plus" margin />
            Cadastre-se
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
